import React, { useState } from "react";
import Download from "../components/Download";
import Games from "../components/Games";
import Hero from "../components/Hero";
import Hosts from "../components/Hosts";
import Influencer from "../components/Influencer";
import Footer from "../layout/Footer";
import { ScrollingProvider, Section } from "react-scroll-section";
import Header from "../layout/Header";
import Modal from "../components/Modal/Modal";
import Host from "../components/Host";
import Play from "../components/Play";

function Home() {
  const [showModal, setShowModal] = React.useState(false);
  const [user, setUser] = useState("");
  const [token, setToken] = useState("");
  const [userMail, setUserMail] = useState("");

  return (
    <>
      <ScrollingProvider>
        <Header user={user} setShowModal={setShowModal} />
        <Section id="home">
          <Hero />
        </Section>
        <Section id="games">
          <Hosts />
        </Section>
        <Section id="rewards">
          <Influencer />
          <Games />
        </Section>
        <Section id="host">
          <Host />
        </Section>
        <Section id="play">
          <Play />
        </Section>
        <Section id="download">
          <Download />
          <Footer />
        </Section>
      </ScrollingProvider>

      {showModal && (
        <Modal
          onClickOutside={() => setShowModal(false)}
          user={user}
          setUser={setUser}
          showModal={showModal}
          setShowModal={setShowModal}
          userMail={userMail}
          setUserMail={setUserMail}
          token={token}
          setToken={setToken}
        />
      )}
    </>
  );
}

export default Home;
