import React, { useState } from "react";
import { ReactComponent as Logo } from "../assets/icons/footer-logo.svg";

import { ReactComponent as Facebook } from "../assets/icons/facebook.svg";
import { ReactComponent as Instagram } from "../assets/icons/instagram.svg";
import { ReactComponent as Twitter } from "../assets/icons/twitter.svg";
import { ReactComponent as Youtube } from "../assets/icons/youtube.svg";
import { ReactComponent as Tiktok } from "../assets/icons/tiktok.svg";

import { ReactComponent as Copyright } from "../assets/icons/copyright.svg";
import { URLS } from "../constants/urls";
import "../styles/Footer.css";
import { Link } from "react-router-dom";

const urlLinks = [
  { name: "Privacy Policy", id: "privacyPolicy", link: URLS.APP_URL+"privacypolicy"},
  { name: "FAQs", id: "faqs", link:URLS.APP_URL+"faqs" },
  { name: "Terms of Use", id: "termsOfUse", link:URLS.APP_URL+"termsofuse" },
  { name: "Announcements", id: "hostAgreement", link:URLS.APP_URL+"hostAgreement" },
  { name: "Contact", id: "contact" , link: "https://us10.list-manage.com/contact-form?u=f56a4900316b47255ac1781b4&form_id=36a91add6577664a83e019a2a16383f2"},
];

function Footer() {
  const [viewing, setViewing] = useState("none");
  const pages = {
    none: <></>,
    privacyPolicy: <></>,
  };

  // if viewing !== none => child  => pages[viewing]

  return (
    <footer className="footer-container">
      <div className="footer-wrapper">
        <div className="footer-content">
          {/* Left */}
          <section className="footer-content-left">
            <Logo className="footer-logo" />
            <h4 className="footer-content-title">About us</h4>
            <p className="footer-txt">
              Fun Comp is a social media accessory that empowers influencers,
              artists, content creators, streamers, gamers, businesses,
              organizations and ordinary people with a new way to engage,
              entertain and expand their followers with personalized games that
              allows the host and players an opportunity to earn thousands of
              dollars.{" "}
            </p>

            <p className="footer-dscrptn">
              Right of withdrawal, Conditions, Data protection, Imprint
            </p>

            <div className="social-icons">
              <Link to="https://www.facebook.com/funcompfun" target="_blank">
                <Facebook className="social-icon" />
              </Link>
              <Link to="https://twitter.com/FunCompFun" target="_blank">
                <Twitter className="social-icon" />{" "}
              </Link>
              <Link to="https://www.instagram.com/funcompfun/" target="_blank">
                {" "}
                <Instagram className="social-icon" />{" "}
              </Link>
              <Link to="http://www.tiktok.com/@funcompfun" target="_blank">
                <Tiktok className="social-icon" />{" "}
              </Link>
            </div>
          </section>

          {/* Center */}
          <section className="footer-content-center">
            <h4 className="help-title">Subscribe for daily updates </h4>

            <form action="https://funcomp.us10.list-manage.com/subscribe/post?u=f56a4900316b47255ac1781b4&amp;id=a7691e86cd&amp;f_id=00b4d4e5f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank">
              <div className="input-wrapper mc-field-group" id="mc_embed_signup_scroll">
                <input
                  className="input-fld"
                  type="text"
                  placeholder="First Name"
                  required
                  name="FNAME"
                  id="mce-FNAME"
                />
                <input
                  className="input-fld"
                  type="text"
                  name="LNAME"
                  placeholder="Last Name "
                  id="mce-LNAME"
                  required
                />
              </div>

              <div id="mce-responses" className="clear">
                <div
                  className="response"
                  id="mce-error-response"
                  style={{ display: "none" }}
                ></div>
                <div
                  className="response"
                  id="mce-success-response"
                  style={{ display: "none" }}
                ></div>
              </div>
              <div
                aria-hidden="true"
                style={{ position: "absolute", left: "-5000px" }}
              >               <input
                  type="text"
                  name="b_f56a4900316b47255ac1781b4_a7691e86cd"
                  tabIndex="-1"
                  value=""
                />
              </div>
              <div className="input-wrapper mc-field-group">
                <input
                  className="input-fld"
                  type="email"
                  id="mce-EMAIL"
                  name="EMAIL"
                  placeholder="Enter Email *"
                  required
                />
                <button type="submit" className="footer-btn"  name="subscribe" id="mc-embedded-subscribe">
                  Subscribe
                </button>
              </div>
            </form>
            <script type="text/javascript" src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"></script>
            <script type="text/javascript">
                {`(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]='EMAIL';ftypes[0]='email';fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text';fnames[3]='ADDRESS';ftypes[3]='address';fnames[4]='PHONE';ftypes[4]='phone';fnames[5]='BIRTHDAY';ftypes[5]='birthday';}(jQuery));var $mcj = jQuery.noConflict(true);`}
            </script>
            <h4 className="footer-content-title">Pricing</h4>
            <p className="footer-txt">
              Fun Comp is FREE for Non-Pay To Play Games that pay winners in
              Virtual Currency. Pay To Play Games start at $1.00 and increase
              depending on the game.
            </p>

            <h4 className="footer-content-title">What you win</h4>
            <p className="footer-txt">
              Our 1st Place Prizes for Pay To Play Games start at $100 and
              increases depending on the competition. Some Pay To Play Games pay
              out $1,000.00 US or MORE to a single winner.
            </p>

            <h4 className="footer-content-title">Refund Policy</h4>
            <p className="footer-txt">
              Our Refund Policy can be found in our Terms of Service.
            </p>
          </section>

          {/* Right */}
          <section className="footer-content-right">
            <h4 className="help-title">Information help </h4>
            {urlLinks?.map(({ id, name, link }) => {
              function handleLinkClick(link) {
                setViewing(link);
              }
              return (
                <Link key={id} to={link} target="_blank" rel="noopener noreferrer" className="help-lnk">
                  {name}
                </Link>
              );
            })}

            <h4 className="footer-content-title">Disclaimers</h4>
            <p className="footer-txt">
              Royalty free stock images and videos of some locations, some
              products and some people have been used for creating the visuals
              of this website. These locations, products and people have not
              endorsed Fun Comp and the people may or may not use Fun Comp.
              <br />
              <br />
              All trademarks and original content are owned by their respective
              creators and companies.
            </p>
          </section>
        </div>
      </div>
      <div className="copyright-box">
        <Copyright />
        <p>
          All Right Reserved by <span>FUN COMP</span>
        </p>
      </div>
    </footer>
  );
}

export default Footer;
