import { useEffect } from 'react';
import { useState } from 'react';
import '../../styles/Modal.css';
import { URLS } from '../../constants/urls';
import ErrorHandler from '../ErrorHandler';

const CreateUserName = ({ userMail, setUserMail , token , setToken }) => {
  const [message, setMessage] = useState('');
  const SetUserNameURL = URLS.API_URL + "/auth/username";  
  const verifyUserNameURL = URLS.API_URL + "/users/username";  
  const [name, setName] = useState('');
 
  const [verifyStatus, setVerifyStatus] = useState(false);
  const [confirmStatus, setCofirmStatus] = useState({
    state: '',
    success: false,
    message: '',
  });

  const setUsernameOptions = {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      username: name,
      email: userMail,
    }),
  };

  const verifyUsernameOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    body: JSON.stringify({ 
      username: name,
    }),
  };

  const changeName = async (e) => {
    e.preventDefault();
    try {
      setCofirmStatus({ ...confirmStatus, state: 'pending' });
      let res = await fetch(SetUserNameURL, setUsernameOptions);
      let resJson = await res.json();
      if (res.status === 200) {
        const { data } = resJson; 
        setCofirmStatus({
          ...confirmStatus,
          state: 'success',
          success: true,
          message: resJson.message,
        });
        window.location.replace(
          URLS.APP_URL+`web-login?token=${token}`
       );
      } else {
        setCofirmStatus({
          ...confirmStatus,
          state: 'failed',
          success: false,
          message: resJson.message,
        });
      }
    } catch (error) {
      return <ErrorHandler error={error} />;
    }
  };

  const VerifyUsername = async (e) => {
    e.preventDefault();
    try {  
      let res = await fetch(verifyUserNameURL, verifyUsernameOptions);
      if (res.status === 200) {
        setVerifyStatus(true)
      }else{
        setVerifyStatus(false)
      }
    } catch (error) {
      return <ErrorHandler error={error} />;
    }
  };

  useEffect(() => {
    if (confirmStatus.success && token) {
      window.location.replace(
         URLS.APP_URL+`web-login?token=${token}`
      );
    }
  }, [confirmStatus.success, token]);

  return (
    <div className="username-container">
      <h4 className="username-header">Create UserName</h4>
      <div className="modal-body">
        <form onSubmit={changeName} className="modal-form">
          <div className="email-wrapper mb-20">
            <input
              onChange={(e) => setUserMail(e.target.value)}
              required
              id="email"
              type="email"
              placeholder="Email"
              value={userMail === "" ? "" : userMail}
            />
            <label htmlFor="email">Email</label>
          </div>
          <div className="email-wrapper mb-20">
            <input
             onChange={(e) => {
              VerifyUsername(e);
              setName(e.target.value)
              }}
              required
              id="name"
              type="text"
              pattern="[A-Za-z0-9]+"
              onInvalid={() =>
                setMessage("UserName Can't contain any special characters")
              }
              placeholder="Name"
            />
             
            <label htmlFor="name">Username</label>
          </div>
          {verifyStatus ? <input
            type="submit"
            className="logIn-btn"
          value={confirmStatus.state === 'pending' ? 'Changing...' : 'Change'}
            
          /> : <input
          type="submit"
          className="logIn-btn "
          value={confirmStatus.state === 'pending' ? 'Changing...' : 'Change'}
          style={{background:"red"}}
          disabled
        />}
          
        </form>
        {message ? <p className="error-message">{message}</p> : null}
        {confirmStatus.success ? (
          <p className="success-text">{confirmStatus.message} </p>
        ) : (
          <p className="error-message">{confirmStatus.message}</p>
        )}

        {}
      </div>
    </div>
  );
};

export default CreateUserName;
