import { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ReactComponent as PasswordHide } from "../../assets/icons/password-hide.svg";
import { ReactComponent as PasswordShow } from "../../assets/icons/password-show.svg";
import { ReactComponent as FileUpload } from "../../assets/icons/file_upload.svg";
import { ReactComponent as Trash } from "../../assets/icons/trash.svg";
import "../../styles/Modal.css";
import ErrorHandler from "../ErrorHandler";
import { URLS } from "../../constants/urls";

const calculateAge = (birthdate) => {
  const today = new Date();
  const birthDate = new Date(birthdate);
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();

  // If the birthdate hasn't occurred yet this year
  if (
    monthDiff < 0 ||
    (monthDiff === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }

  return age;
};

const Signup = ({
  setUser,
  createUserNameActive, 
  handleUsernameClick,
  userMail,
  setUserMail,
  token,
  setToken,
}) => {
  const [type, setType] = useState("text");
  const date = new Date();

  const setMonth = (month) => {
    switch (month) {
      case 1:
        return "Jan";
      case 2:
        return "Feb";
      case 3:
        return "Mar";
      case 4:
        return "Apr";
      case 5:
        return "May";
      case 6:
        return "Jun";
      case 7:
        return "Jul";
      case 8:
        return "Aug";
      case 9:
        return "Sep";
      case 10:
        return "Oct";
      case 11:
        return "Nov";
      case 12:
        return "Dec";
      default:
        return "Jan";
    }
  };

  const [passwordShow, setPasswordShow] = useState(false);
  const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);
  const [isActionTaken, setIsActionTaken] = useState(false);
  const [matched, setMatched] = useState(false);
  const [errors, setErrors] = useState([]);

  //USER__INFO

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [sex, setSex] = useState("");
  const [host, setHost] = useState("no");

  let defaultHostValue = {
    isHostTeacher: false,
    birthdate: "",
    hostLicenseImage: "",
    numberOfHostFollowers: "",
    hostSocialMediaLink: "",
    mobileDevices: [
      {
        deviceId: "Web",
        registrationToken: "Web",
        os: "Web",
      },
    ],
  };
  const [other, setOther] = useState(defaultHostValue);

  const [status, setStatus] = useState({
    state: "",
    success: false,
    message: "",
  });

  const [verifyStatus, setVerifyStatus] = useState({
    state: "",
    success: false,
    message: "",
  });

  const [verifiedStatus, setVerifiedStatus] = useState({
    state: "",
    success: false,
    message: "",
  });

  const [firstPin, setFirstPin] = useState("");
  const [secondPin, setSecondPin] = useState("");
  const [thirdPin, setThirdPin] = useState("");
  const [fourthPin, setFourthPin] = useState("");
  const [buttonClicked, setButtonClicked] = useState(false);
  const [companyName, setCompanyName] = useState("");
  let [phone, setPhone] = useState(""); 
  const [agree, setAgree] = useState(false);
  const [confirmAge, setConfirmAge] = useState(true);
  const underAgeMessage =
    "You are under the age of 18 and you agree that you have parental or guardian permission to use Fun Comp.";

  const url = URLS.API_URL + "/auth/signup";
  const verifyUrl = URLS.API_URL + "/auth/pin/resend";
  const verifiedURL = URLS.API_URL + "/auth/pin/validate";
  const defaultBirthdate = new Date();
  const maxBirthdate = new Date();
  defaultBirthdate.setFullYear(defaultBirthdate.getFullYear() - 18);
  maxBirthdate.setFullYear(maxBirthdate.getFullYear() - 13);
   defaultHostValue.birthdate = defaultBirthdate.toISOString().split("T")[0]; 
  const maxBirthdateString = maxBirthdate.toISOString().split("T")[0];

  //Sign up request options

  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/form-data",
      "Content-Type": "application/json",
    },
    // body: formDataJsonString,
    body: JSON.stringify({
      firstName: firstName,
      lastName: lastName,
      email: userMail,
      password: password,
      birthdate: other.birthdate,
      // gender: sex.toUpperCase(),
      // companyName: companyName,
      phone: phone.replace("-", ""),
      hostRequest: host,
      isHostTeacher: host ? other.isHostTeacher : false,
      numberOfHostFollowers: other.numberOfHostFollowers,
      hostSocialMediaLink: other.hostSocialMediaLink,
      hostLicenseImage: other.hostLicenseImage,
      mobileDevices: [
        {
          deviceId: "Web",
          registrationToken: "Web",
          os: "Web",
        },
      ],
    }),
  };

  //Sign Up request

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsActionTaken(true);
    if (!confirmAge) {
      if (window.confirm(underAgeMessage)) {
        setConfirmAge(true);
      } else {
        setConfirmAge(false);
        return;
      }
    }
    if (!agree) {
      setErrors((error) => ["Make sure you agree of terms of use"]);
      return;
    }

    if (password.length < 6 || confirmPass.length < 6) {
      setMatched(false);
      setErrors((error) => ["Password must be at least 6 characters long"]);
      return;
    }
    if (password !== confirmPass) {
      setMatched(false);
      setErrors((error) => ["Passwords do not match"]);
      return;
    } else setMatched(true);

    try {
      setStatus({ ...status, state: "pending" });
      let res = await fetch(url, requestOptions);
      let resJson = await res.json();
      if (res.status === 200) {
        setStatus({
          ...status,
          state: "success",
          success: true,
          message: resJson.message,
        });
        setPassword("");
        setIsActionTaken(false);
      } else {
        setStatus({
          ...status,
          state: "failed",
          success: false,
          message: resJson.message,
        });
      }
    } catch (error) {
      return <ErrorHandler error={error} />;
    }

    return matched;
  };

  //Verify Pin request options

  const verifyOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      email: userMail,
    }),
  };

  const getVerifyCode = async () => {
    try {
      setVerifyStatus({ ...verifyStatus, state: "pending" });
      let res = await fetch(verifyUrl, verifyOptions);
      let resJson = await res.json();
      if (res.status === 200) {
        setVerifyStatus({
          ...verifyStatus,
          state: "success",
          success: true,
          message: resJson.message,
        });
      } else {
        setVerifyStatus({
          ...verifyStatus,
          state: "failed",
          success: false,
          message: resJson.message,
        });
      }
    } catch (error) {
      return <ErrorHandler error={error} />;
    }
  };

  useEffect(() => {
    if (status.success) {
      getVerifyCode();
    }
  }, [status.success]);

  const handleVerifyInputChange = (e) => {
    const { maxLength, value, name } = e.target;
    const [fieldName, fieldIndex] = name.split("-");
    if (value.length >= maxLength) {
      if (parseInt(fieldIndex, 10) < 4) {
        const nextInput = document.querySelector(
          `input[name=${fieldName}-${parseInt(fieldIndex, 10) + 1}]`
        );
        if (nextInput !== null) {
          nextInput.focus();
        }
      }
    }
  };

  let myPin =
    firstPin && secondPin && thirdPin && fourthPin
      ? parseInt(firstPin + secondPin + thirdPin + fourthPin, 10)
      : "";

  const varifiedOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      email: userMail,
      pin: myPin,
    }),
  };

  const handlePinSubmission = async (e) => {
    e.preventDefault();
    try {
      let res = await fetch(verifiedURL, varifiedOptions);
      let resJson = await res.json();
      if (res.status === 200) {
        const { data } = resJson;
        setUser(data.user.firstName + " " + data.user.lastName);
        setVerifiedStatus({
          ...verifiedStatus,
          state: "success",
          success: true,
          message: resJson.message,
        });
        setToken(data.token)
        handleUsernameClick();
      } else {
        setVerifiedStatus({
          ...verifiedStatus,
          state: "failed",
          success: false,
          message: resJson.message,
        });
      }
    } catch (error) {
      return <ErrorHandler error={error} />;
    }
  };

  const handleBirthDateChange = (e) => { 
    setOther({ ...other, birthdate: e  }); 
    const calculatedAge = calculateAge(e); 
    // Check if age is below 18
    if (calculatedAge < 18) {
      // Show modal

      if (window.confirm(underAgeMessage)) {
        setConfirmAge(true);
      } else {
        setConfirmAge(false);
      }
    } else {
      // Do something else, e.g., proceed with the form submission
      setConfirmAge(true);
    }
  };

  function formatPhoneNumber(value) {
    if (!value) return value;

    const phoneNumber = value.replace(/[^\d]/g, "");

    const phoneNumberLength = phoneNumber.length;

    if (phoneNumberLength < 4) return phoneNumber;

    if (phoneNumberLength < 7) {
      return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
    }

    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  }

  function handlePhoneChange(e) {
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    setPhone(formattedPhoneNumber);
  }

  return (
    <div className="modal-body fade-out-animation">
      {status.success && !createUserNameActive ? (
        <>
          <h2 className="verify-title mb-20">Verify Your Account</h2>
          <p className="verify-subtitle mb-20">
            We Sent A Verification Code To '
            <span style={{ textDecoration: "underline" }}>{userMail}</span>'.
            Enter The Code Here To Verify Your Account.
          </p>
          <form
            onSubmit={handlePinSubmission}
            className="verify-form"
            method="post"
          >
            <div className="input-wrapper mb-20">
              <input
                className="verify-input"
                name="verify-1"
                type="text"
                maxLength={1}
                onChange={(e) => {
                  handleVerifyInputChange(e);
                  setFirstPin(e.target.value);
                }}
                size={1}
              />
              <input
                className="verify-input"
                name="verify-2"
                type="text"
                maxLength={1}
                onChange={(e) => {
                  handleVerifyInputChange(e);
                  setSecondPin(e.target.value);
                }}
                size={1}
              />
              <input
                className="verify-input"
                name="verify-3"
                type="text"
                maxLength={1}
                onChange={(e) => {
                  handleVerifyInputChange(e);
                  setThirdPin(e.target.value);
                }}
                size={1}
              />
              <input
                className="verify-input"
                type="text"
                name="verify-4"
                maxLength={1}
                onChange={(e) => {
                  handleVerifyInputChange(e);
                  setFourthPin(e.target.value);
                }}
                size={1}
              />
            </div>

            {verifiedStatus.state === "failed" && (
              <p className="error-message">{verifiedStatus.message}</p>
            )}
            <div className="resend-box mb-20">
              {!buttonClicked && (
                <p onClick={() => setButtonClicked(true)} className="link-text">
                  Resend
                </p>
              )}
              {buttonClicked && (
                <p className="norm-text">
                  {verifyStatus.state === "pending" ? "Sending..." : "Sent"}
                </p>
              )}
            </div>

            {verifiedStatus.state === "success" ? (
              <p className="success-text">Successfully Verified</p>
            ) : (
              <input
                className="verify-btn"
                type="submit"
                value={
                  verifiedStatus.state === "pending" ? "Submitting" : "Verify"
                }
              />
            )}

             
          </form>
        </>
      ) : (
        <form onSubmit={handleSubmit} className="modal-form">
          <div className="email-wrapper mb-20">
            <input
              onChange={(e) => setFirstName(e.target.value)}
              required
              id="fname"
              type="text"
              placeholder="First Name"
            />
            <label htmlFor="fname">First Name</label>
          </div>

          <div className="email-wrapper mb-20">
            <input
              onChange={(e) => setLastName(e.target.value)}
              required
              id="sname"
              type="text"
              placeholder="Last Name"
            />
            <label htmlFor="sname">Last Name</label>
          </div>

          <div className="email-wrapper mb-20">
            <input
              onChange={(e) => setUserMail(e.target.value)}
              required
              id="email"
              type="email"
              placeholder="Email"
            />
            <label htmlFor="email">Email</label>
          </div>
          <div className="password-wrapper mb-20">
            <div className="password-input-wrapper">
              <input
                required
                className="password-input"
                onChange={(e) => setPassword(e.target.value)}
                id="password"
                type={passwordShow ? "text" : "password"}
                placeholder="Password"
              />
              {passwordShow ? (
                <PasswordHide
                  onClick={() => setPasswordShow(false)}
                  className="password-icon"
                />
              ) : (
                <PasswordShow
                  onClick={() => setPasswordShow(true)}
                  className="password-icon"
                />
              )}
            </div>
            <label htmlFor="password">Password</label>
          </div>
          <div className="password-wrapper mb-20">
            <div className="password-input-wrapper">
              <input
                required
                className="password-input"
                onChange={(e) => setConfirmPass(e.target.value)}
                id="confirm-password"
                type={confirmPasswordShow ? "text" : "password"}
                placeholder="Confirm Password"
              />
              {confirmPasswordShow ? (
                <PasswordHide
                  onClick={() => setConfirmPasswordShow(false)}
                  className="password-icon"
                />
              ) : (
                <PasswordShow
                  onClick={() => setConfirmPasswordShow(true)}
                  className="password-icon"
                />
              )}
            </div>
            <label htmlFor="confirm-password">Confirm Password</label>
          </div>

          <div className="email-wrapper mb-20">
     {/* <input
              id="date-of-birth"
              type={type} 
              onFocus={() => setType('Date')}
              onChange={(e) => handleBirthDateChange(e)}
              placeholder={defaultBirthdateString}
              max={maxBirthdateString}
            />   */}
          <DatePicker 
              selected={other.birthdate}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dateFormat="yyyy-MM-dd"
              dropdownMode="select"
              maxDate={maxBirthdateString}
              onChange={(date) => handleBirthDateChange(date.toISOString().split("T")[0])}
            />  
            <label htmlFor="date-of-birth">Date of Birth</label>
          </div>
          <div className="email-wrapper mb-20">
            <input
              id="phone"
              type="tel"
              name="phone"
              pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
              placeholder="605-293-9611"
              title="Mobile Number "
              value={phone}
              onChange={(e) => handlePhoneChange(e)}
            />
            <label htmlFor="phone">Phone No</label>
          </div>
          {/* <div className="email-wrapper mb-20">
            <input
              onChange={(e) => setCompanyName(e.target.value)}
              required
              id="company-name"
              type="text"
              placeholder="Company Name"
            />
            <label htmlFor="company-name">Company Name</label>
          </div> */}
          {/* <div className="modal-input-wrapper mb-20">
            <legend className="input-label ">Gender</legend>
            <div className="options">
              <div className="option">
                <input
                  required
                  onChange={(e) => setSex(e.target.value.toUpperCase())}
                  type="radio"
                  id="male"
                  name="sex"
                  value="MALE"
                />
                <label htmlFor="male">Male</label>
              </div>
              <div className="option">
                <input
                  onChange={(e) => setSex(e.target.value)}
                  type="radio"
                  id="female"
                  name="sex"
                  value="FEMALE"
                />
                <label htmlFor="female">Female</label>
              </div>
              <div className="option">
                <input
                  onChange={(e) => setSex(e.target.value)}
                  type="radio"
                  id="other"
                  name="sex"
                  value="OTHER"
                />
                <label htmlFor="other">Other</label>
              </div>
            </div>
          </div> */}

          {/* <div className="modal-input-wrapper mb-20">
            <legend className="input-label ">
              Do you want to make your self as host?
            </legend>
            <div className="options">
              <div className="option">
                <input
                  checked={host === 'no'}
                  onChange={(e) => {
                    setHost(e.target.value);
                    setOther(defaultHostValue);
                  }}
                  type="radio"
                  id="no"
                  name="host"
                  value="no"
                />
                <label htmlFor="no">No</label>
              </div>
              <div className="option">
                <input
                  checked={host === 'yes'}
                  onChange={(e) => setHost(e.target.value)}
                  type="radio"
                  id="yes"
                  name="host"
                  value="yes"
                />
                <label htmlFor="yes">Yes</label>
              </div>
            </div>
          </div> */}
          {/* {host === 'yes' && <HostSignup other={other} setOther={setOther} />} */}
          <label style={{ display: "inline-block", marginBottom: "1rem" }}>
            <input
              type="checkbox"
              checked={agree}
              onChange={() => setAgree(!agree)}
            />
            Creating An Account Means You’re Okay With Our{" "}
            <a
              href={`${URLS.APP_URL}termsofuse`}
              target="_blank"
              rel="noreferrer"
            >
              Terms Of Use
            </a>{" "}
            And{" "}
            <a
              href={`${URLS.APP_URL}privacypolicy`}
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              Privacy Policy
            </a>
          </label>
          {isActionTaken && !matched && (
            <>
              {errors?.map((error, id) => (
                <p key={id} className="error-message">
                  {error}
                </p>
              ))}
            </>
          )}
          {status.state === "failed" && (
            <p className="error-message">{status.message}</p>
          )}
          {status.state === "Success" ? (
            <p className="success-text">Signed Up</p>
          ) : (
            <input
              type="submit"
              className="logIn-btn"
              value={status.state === "pending" ? "Signing Up" : "Sign Up"}
            />
          )}

          {/* <div className="modal-footer">
            <div className="divider mb-20">Or</div>
            <div className="login-icons">
              <Apple className="login-icon" /> <Google className="login-icon" />
            </div>
          </div> */}
        </form>
      )}
    </div>
  );
};

export default Signup;

const HostSignup = ({ other, setOther }) => {
  const url = URLS.API_URL + "/host-questions";
  const [questions, setQuestions] = useState([]);
  const [message, setMessage] = useState("");
  const [fileName, setFileName] = useState("");

  useEffect(() => {
    fetch(url)
      .then((res) => res.json())
      .then(({ data }) => {
        setQuestions(data.questions);
      });
  }, []);

  const [teacher, setTeacher] = useState("");
  const fileRef = useRef(null);
  const [file, setFile] = useState("");
  const imageRef = useRef(null);
  const [selectValue, setSelectValue] = useState("100 to 5,000");

  const handleImageChange = (e) => {
    const myFile = e.target.files[0];
    const fileSize = myFile.size / 1024;
    setFileName(myFile.name);

    if (fileSize > 1024) {
      setMessage("File size should be less than 1mb");
    }
    if (e.target.files && myFile) {
      const imageFile = myFile;
      const reader = new FileReader();
      reader.addEventListener("load", (e) => {
        setFile(e.target.result);
        setOther({ ...other, hostLicenseImage: e.target.result.toString() });
      });
      reader.readAsDataURL(imageFile);
    }
  };

  const handleFileClick = () => {
    fileRef.current.click();
  };

  useEffect(() => {
    setOther({ ...other, numberOfHostFollowers: selectValue });
  }, [selectValue, other, setOther]);

  return (
    <>
      {questions && questions[0] && (
        <div className="modal-input-wrapper mb-20">
          <legend className="input-label ">{questions[0].question}</legend>
          <div className="options">
            <div className="option">
              <input
                required
                checked={teacher === "no"}
                onChange={(e) => {
                  setTeacher(e.target.value);
                  setOther({ ...other, isHostTeacher: false });
                }}
                type={questions[0].imputType}
                id="no"
                name="teacher"
                value="no"
              />
              <label htmlFor="no">No</label>
            </div>
            <div className="option">
              <input
                required
                checked={teacher === "yes"}
                onChange={(e) => {
                  setTeacher(e.target.value);
                  setOther({ ...other, isHostTeacher: true });
                }}
                type={questions[0].imputType}
                id="yes"
                name="teacher"
                value="yes"
              />
              <label htmlFor="yes">Yes</label>
            </div>
          </div>
        </div>
      )}
      {questions && questions[1] && (
        <div className="modal-input-wrapper mb-20">
          <label htmlFor="followers">{questions[1].question}</label>
          <select
            required
            defaultValue={"100 to 5,000"}
            onChange={(e) => {
              setSelectValue(e.target.value);
            }}
            className="followers-group"
            name="followers"
            id="followers"
          >
            <option value={"100 to 5,000"}>100 to 5,000</option>
            <option value={"5,001 to 10,000"}>5,001 to 10,000</option>
            <option value={"10,001 to 25,000"}>10,001 to 25,000</option>
            <option value={"25,001 to 50,000"}>25,001 to 50,000</option>
            <option value={"50,001 to 100,000"}>50,001 to 100,000</option>
            <option value={"100,001 to 250,000"}>100,001 to 250,000</option>
            <option value={"250,001 to 500,000"}>250,001 to 500,000</option>
            <option value={"500,001 to 1M"}>500,001 to 1M</option>
            <option value={"1M+"}>1M+</option>
          </select>
        </div>
      )}
      {questions && questions[2] && (
        <div className="modal-input-wrapper rv mb-20">
          <input
            required
            type="url"
            id="social-media"
            onChange={(e) =>
              setOther({ ...other, hostSocialMediaLink: e.target.value })
            }
            placeholder="ex: https://www.youtube.com/channel"
          />
          <label htmlFor="social-media">{questions[2].question}</label>
        </div>
      )}
      {questions && questions[3] && (
        <div className="image-input-wrapper mb-20">
          <div className="d-f ">
            <label htmlFor="file">{questions[3].question}</label>
            <p className="upload-button" onClick={handleFileClick}>
              <FileUpload />
              Click to Upload
            </p>
            <input
              required
              style={{ display: "none" }}
              onChange={handleImageChange}
              ref={fileRef}
              type="file"
              id="file"
            />
          </div>
          {file && (
            <div className="image-upload-box">
              <img
                src={file}
                alt="uploaded file"
                className="uploaded-image mb-10"
                ref={imageRef}
              />
              <p onClick={() => setFile(null)}>
                {fileName.slice(0, 10)} ... <Trash />
              </p>
            </div>
          )}
        </div>
      )}

      {message ? <p className="error-message">{message}</p> : null}
    </>
  );
};
