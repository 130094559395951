import React from "react";
import { ReactComponent as Logo } from "../assets/icons/logo.svg";
import "../styles/Header.css";
import { useScrollSection } from "react-scroll-section";

const Header = ({ setShowModal, user }) => {
  const [show, setShow] = React.useState(false);
  const homeSection = useScrollSection("home");
  const gamesSection = useScrollSection("games");
  const rewardsSection = useScrollSection("rewards");
  const downloadSection = useScrollSection("download");

  return (
    <header className="header-wrapper">
      <div className="header-container">
        <a href="/">
          <Logo className="logo" />
        </a>

        <nav>
          <ul className="main-menu">
            <a
              href="#home"
              onClick={homeSection.onClick}
              selected={homeSection.selected}
              className={homeSection.selected ? "active" : ""}
            >
              Home
            </a>
            <a
              href="#games"
              onClick={gamesSection.onClick}
              selected={gamesSection.selected}
              className={gamesSection.selected ? "active" : ""}
            >
              Games
            </a>
            <a
              href="#rewards"
              onClick={rewardsSection.onClick}
              selected={rewardsSection.selected}
              className={rewardsSection.selected ? "active" : ""}
            >
              Rewards
            </a>
            <a
              href="#download"
              onClick={downloadSection.onClick}
              selected={downloadSection.selected}
              className={downloadSection.selected ? "active" : ""}
            >
              Download
            </a>

            <button
              disabled={user}
              onClick={() => setShowModal(true)}
              className="register-btn"
            >
             Register / Sign In
            </button>
          </ul>
        </nav>

        <div className="toggle-menu">
          {show && (
            <nav>
              <ul className="mobile-menu">
                <a
                  href="#home"
                  onClick={() => {
                    homeSection.onClick();
                    setShow(false);
                  }}
                  selected={homeSection.selected}
                  className={homeSection.selected ? "active" : ""}
                >
                  Home
                </a>
                <a
                  href="#games"
                  onClick={() => {
                    gamesSection.onClick();
                    setShow(false);
                  }}
                  selected={gamesSection.selected}
                  className={gamesSection.selected ? "active" : ""}
                >
                  Games
                </a>
                <a
                  href="#rewards"
                  onClick={() => {
                    rewardsSection.onClick();
                    setShow(false);
                  }}
                  selected={rewardsSection.selected}
                  className={rewardsSection.selected ? "active" : ""}
                >
                  Rewards
                </a>

                <a
                  href="#download"
                  onClick={() => {
                    downloadSection.onClick();
                    setShow(false);
                  }}
                  selected={downloadSection.selected}
                  className={downloadSection.selected ? "active" : ""}
                >
                  Download
                </a>

                <button
                  disabled={user}
                  onClick={() => setShowModal(true)}
                  className="mobile-rgister-btn"
                >
                 Register / Sign In
                </button>
              </ul>
            </nav>
          )}
          <div className="box">
            <div
              onClick={() => setShow(!show)}
              className={`navBtn ${show ? "active" : "not-active"}`}
            >
              <span className="first"></span>
              <span className="small"></span>
              <span className="third"></span>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
